import React from "react";
import Pagination from "../../component/pagination/Pagination";
import DailyBalancesTable from "./DailyBalancesTable";

const BalanceHistoryList = () => {
    return (
        <>
            <DailyBalancesTable/>
        </>
    )
}

export default BalanceHistoryList