import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
import Axios from 'axios'
import { BASE_URL,HEADERS } from "../../config/config"
import { ShowToast } from "../../component/utils/ShowToast"

export const fetchItem = createAsyncThunk(
    'items/fetchItem',
    async (itemId)=>{
        try{
            const response = await Axios.get(
                `${BASE_URL}/items/${itemId}`,
                {
                    headers: HEADERS()
                }
            )
            const data = response.data.data
            return data
        }catch(error){
            if(error.response.status === 401){
                ShowToast('error','ကျေးဇူးပြု၍လော့အင်ပြန်ဝင်ပါ')
                localStorage.removeItem('recycleAppAuth')
            }else{
                ShowToast('error','မှားယွင်းနေပါသည်')
            }
            throw new Error(error)
        }
    }
)

const itemSlice = createSlice({
    name: 'item',
    initialState:{
        loading: false,
        data: [],
        error: null
    },
    reducers: {
        resetSingleItem : (state)=>{
            state.loading = false
            state.data = []
            state.error = null
            return state
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(fetchItem.pending, (state)=>{
            state.loading = true
            state.data = []
            state.error = null
            return state
        })
        .addCase(fetchItem.fulfilled, (state,action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
            return state
        })
        .addCase(fetchItem.rejected, (state,action)=>{
            state.loading = false
            state.data = []
            state.error = action.error
            return state
        })
    }
})

export const { resetSingleItem } = itemSlice.actions

export default itemSlice.reducer